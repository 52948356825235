import React from 'react'
import { graphql } from 'gatsby'
import { uniqBy, sortBy } from 'lodash'
import Select from 'react-select'
import Layout from '../components/layout'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { SectionTwoThirdsRight } from '../components/SectionTwoThirdsRight'

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      industry: {
        value: '',
        label: 'All',
      },
      country: {
        value: '',
        label: 'All',
      },
    }
  }

  handleChangeIndustry = selectedOption => {
    this.setState({ industry: selectedOption, country: { value: '', label: 'All' } })
    trackCustomEvent({
      category: 'clients',
      action: 'select industry',
      label: selectedOption.label,
    })
  }

  handleChangeCountry = selectedOption => {
    this.setState({ country: selectedOption, industry: { value: '', label: 'All' } })
    trackCustomEvent({
      category: 'clients',
      action: 'select country',
      label: selectedOption.label,
    })
  }

  render() {
    const { data, location } = this.props

    const { allClientsJson } = data
    const { industry, country } = this.state

    const clients = allClientsJson.edges
      .map(d => {
        const { node } = d

        return { ...node }
      })
      .filter(
        client =>
          (industry.value === '' && country.value === '') ||
          industry.value === client.industry ||
          country.value === client.country
      )

    const industryOptions = sortBy(
      uniqBy(
        clients.map(client => {
          return { value: client.industry, label: client.industry }
        }),
        'value'
      ),
      ['label']
    )
    const countryOptions = sortBy(
      uniqBy(
        clients.map(client => {
          return { value: client.country, label: client.country }
        }),
        'value'
      ),
      ['label']
    )

    industryOptions.unshift({ value: '', label: 'All' })
    countryOptions.unshift({ value: '', label: 'All' })

    const industrySelect = () => ({
      value: industry,
      onChange: this.handleChangeIndustry,
      options: industryOptions,
      className: 'clientsSelect',
      placeholder: 'All',
    })
    const countrySelect = () => ({
      value: country,
      onChange: this.handleChangeCountry,
      options: countryOptions,
      className: 'clientsSelect',
      placeholder: 'All',
    })

    const headers = [
      { name: 'Client', className: 'w-50', style: { height: '38px' } },
      { name: 'Industry', className: 'w-25-l w-30 relative dn flex-ns', select: industrySelect() },
      { name: 'Country', className: 'w-25-l w-20 relative dn flex-ns', select: countrySelect() },
    ]

    return (
      <Layout
        type={'home'}
        location={location}
        title="Clients"
        description="Our Clients - We helped more than 100 global organizations and 30 digital startups turn data into a resource to drive awareness, engagement, comprehension and insight."
      >
        <Paragraph />

        <ClientTable>
          <HeaderTable headers={headers} />
          <RowTable clients={clients} industry={industry} country={country} />
        </ClientTable>
      </Layout>
    )
  }
}

const Paragraph = () => (
  <div className="bg-light-gray">
    <div className="mw7-m mw8-l center pv5-ns ph3 pv-1px-s">
      <SectionTwoThirdsRight label="OUR CLIENTS">
        <div>
          We helped more than <span className="corporate">100 global organizations</span> and{' '}
          <span className="startup">30 digital startups</span> turn data into a resource to drive
          awareness, engagement, comprehension and insight.
        </div>
      </SectionTwoThirdsRight>
    </div>
  </div>
)

const HeaderTable = ({ headers }) => (
  <div className="bb b--gray b--black-10 bw1 cf mb2 mb3-m flex">
    {headers.map((header, i) => (
      <div key={i} className={`flex items-center ${header.className}`} style={header.style}>
        <div className="fl-l mr4 pointer mono" style={{ fontSize: '13px' }}>
          {header.name}
        </div>
        {header.select && <SelectTable select={header.select} className={header.className} />}
      </div>
    ))}
  </div>
)

const SelectTable = ({ select }) => {
  const customStyles = {
    menuList: provided => ({
      ...provided,
      minWidth: '200px',
      backgroundColor: 'white',
      transform: 'translateX(-50px)',
    }),
    container: provided => ({ ...provided, minWidth: '130px', fontSize: '12px' }),
    control: provided => ({ ...provided, backgroundColor: 'white', borderStyle: 'none' }),
    indicatorSeparator: provided => ({ ...provided, display: 'none' }),
  }

  return (
    <div className="flex-ns dn dn-m">
      <Select
        value={select.value}
        onChange={select.onChange}
        options={select.options}
        placeholder={select.placeholder}
        styles={customStyles}
      />
    </div>
  )
}

const RowTable = ({ clients }) => {
  return clients.map((client, index) => {
    const { type } = client
    const clientType = type ? client.type.toLowerCase() : ''

    return (
      <div className="bb b--light-gray cf pa1 client-el flex" key={index}>
        <p className={`ma0 fl-l dib-m w-50 ttu tracked f6 ${clientType}`}>{client.name}</p>
        <p
          className={`ma0 fl-l dib-m w-25-l w-30 f6 ${clientType}`}
          style={{ wordBreak: 'break-word' }}
        >
          {client.industry}
        </p>
        <p className={`ma0 fl-l dib-m w-25-l w-20 truncate-l f6 tc tl-ns ${clientType} db-l`}>
          {client.country}
        </p>
      </div>
    )
  })
}

const ClientTable = ({ children }) => {
  return (
    <div className="mw7-m mw8-l center pv5-ns pv4 ph3" style={{ minHeight: '900px' }}>
      {children}
    </div>
  )
}

export const pageQuery = graphql`
  {
    allClientsJson {
      edges {
        node {
          name
          logo
          type
          industry
          country
        }
      }
    }
  }
`
